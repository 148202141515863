export function getPastDiff(date: Date | string) {
    const past = new Date(date);
    const diff = new Date().getTime() - past.getTime();
    if (diff < 60 * 1000) return 'لحظاتی قبل';
    else if (diff < 60 * 60 * 1000) return Math.floor(diff / 60 / 1000) + ' دقیقه قبل';
    else if (diff < 24 * 60 * 60 * 1000) return Math.floor(diff / 60 / 60 / 1000) + ' ساعت قبل';
    else if (diff < 30 * 24 * 60 * 60 * 1000) return Math.floor(diff / 24 / 60 / 60 / 1000) + ' روز قبل';
    else if (diff < 12 * 30 * 24 * 60 * 60 * 1000) return Math.floor(diff / 30 / 24 / 60 / 60 / 1000) + ' ماه قبل';
    else return Math.floor(diff / 12 / 30 / 24 / 60 / 60 / 1000) + ' سال قبل';
}

export function detectOnlineStatus(date: Date | string) {
    const past = new Date(date);
    const diff = new Date().getTime() - past.getTime();
    if (diff < 10 * 60 * 1000) return 'آنلاین';
    else return 'آخرین بازدید ' + getPastDiff(date);
}
