import { authToken } from './storage';

export class WSCommunication {
    private socket: WebSocket;
    private Listener: { stream: string; callback: (e?: any) => void; id: number }[] = [];
    private errorHandlers: { stream: string; callback: (e?: any) => void; id: number }[] = [];

    constructor(onAuth: (e: WSCommunication) => void) {
        this.socket = new WebSocket('wss://achat.vekalapp.com/ws/chat/');
        this.authenticate(onAuth);
        this.messageHandler();
    }

    private messageHandler() {
        this.socket.onmessage = (e) => {
            let obj: any = undefined;
            try {
                obj = JSON.parse(e.data);
            } catch (e) {
                console.log(e);
            }
            if (!obj) return;
            if (obj.successful) {
                console.log('SUCCSSESFUL', this.errorHandlers);
                for (const listener of this.Listener) {
                    if (obj.stream === listener.stream) {
                        listener.callback(obj.payload);
                    }
                }
            } else {
                console.log('ERROR', this.errorHandlers);
                for (const eh of this.errorHandlers) {
                    if (obj.stream === eh.stream) {
                        console.log('HAAHAHAH');
                        console.log('obj', obj);
                        console.log('eh', eh);
                        eh.callback();
                    }
                }
            }
        };
    }

    private authenticate(onAuth: (e: WSCommunication) => void) {
        this.socket.onopen = () => {
            this.send('authorization', {
                key: authToken.get()?.access_token,
            });
        };
        this.subscribe('authorization', (e) => {
            onAuth(this);
        });
    }

    send(stream: string, data: any) {
        this.socket.send(
            JSON.stringify({
                stream: stream,
                payload: data,
            }),
        );
    }

    subscribe<T>(stream: string, callback: (e?: T) => void, errorHandlers?: (e?: any) => void) {
        const id = new Date().getTime();
        this.Listener.push({ stream, callback, id });
        if (errorHandlers) {
            this.errorHandlers.push({ stream, callback, id });
        }
        return id;
    }

    unSubscribe(id: number) {
        this.Listener = this.Listener.filter((item) => item.id !== id);
    }

    close() {
        this.socket.close();
    }
}
