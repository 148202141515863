import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AuthStatus, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import { Header, BottomNavigation } from 'vekalapp-react-utilities';
import { post, responseValidator } from '../scripts/api';
import { authToken } from '../scripts/storage';
import { setAuthStatus, setUserData } from '../redux/actions';
import { API, RoutePath } from '../data';
import UserPanel from './panel/panel.index';
import OnlineChat from './onlineChat/onlineChat.index';
import JudicialFileList from './judicialFile/judicialFileList/judicialFileList.index';
import JudicialFileDetail from './judicialFile/judicialFileDetail/judicialFileDetail.index';
import './vekalapp.style.scss';
import { toast } from 'react-toastify';
import VekalappInit from './vekalapp.script';
import QuestionsAndAnswers from './QA/QaList/QA.index';
import QuestionsDetail from './QA/QaDetail/qaDetail.index';
import MProfileComp from './mobileProfile/mobileProfile.index';
import BuyComp from './buy/buy.index';
import TelephoneAdvisoryCo from './telephoneAdvisory/telephoneAdvisory.index';
import TelephoneAdvisoryDetail from './telephoneAdvisory/telephoneAdvisoryDetail/detail.index';
import UserProfile from './userProfile/userProfile.index';
import ContractsDetails from './contracts/contractsDetail/contractsDetail.index';
import ContractsList from './contracts/contractsList/contractsList.index';
import TicketList from './ticketing/ticketList/ticketList.index';
import TicketCreate from './ticketing/ticketCreate/ticketCreate.index';
import TicketDetail from './ticketing/ticketDetail/ticketDetail.index';
import Authorization from './authorization/authorization.index';
import MyDashboard from './dashboard/dashboard.index';

const Vekalapp: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    useEffect(() => {
        VekalappInit(props.dispatch);
    }, []);
    const history = useHistory();
    return (
        <Switch>
            <Route path={RoutePath.paymentVerify(':key')} component={BuyComp} />
            <Route path={'/oauth'} component={Authorization} />
            <Route path={'*'}>
                {props.isAuth !== AuthStatus.pending && (
                    <Header
                        items={[
                            { name: 'داشبورد', onClick: () => history.push('/') },
                            {
                                name: 'خروج',
                                onClick: () => {
                                    post(API.account.logout, {}).then((res) => {
                                        if (responseValidator(res.status)) {
                                            authToken.remove();
                                            props.dispatch(setUserData(null));
                                            props.dispatch(setAuthStatus(AuthStatus.inValid));
                                        }
                                    });
                                },
                            },
                        ]}
                        myData={props.userData}
                        forceLogin={props.isAuth === AuthStatus.inValid}
                        onLogin={(e) => {
                            authToken.set(e);
                            VekalappInit(props.dispatch);
                        }}
                        onFailLogin={(e) => {
                            if (e) {
                                toast.error('خطایی رخ داده است');
                            }
                        }}
                    />
                )}
                {props.isAuth === AuthStatus.valid && (
                    <UserPanel>
                        <Switch>
                            <Route path={RoutePath.paymentVerify(':key')} component={BuyComp} />
                            <Route path={RoutePath.dashboard} component={MyDashboard} />
                            <Route path={RoutePath.chat.detail(':id')} component={OnlineChat} />
                            <Route path={RoutePath.chat.index} component={OnlineChat} />
                            <Route path={RoutePath.judicialFile.detail(':id')} component={JudicialFileDetail} />
                            <Route path={RoutePath.judicialFile.index} component={JudicialFileList} />
                            <Route path={RoutePath.contracts.detail(':id')} component={ContractsDetails} />
                            <Route path={RoutePath.contracts.index} component={ContractsList} />
                            <Route path={RoutePath.support.create} component={TicketCreate} />
                            <Route path={RoutePath.support.detail(':id')} component={TicketDetail} />
                            <Route path={RoutePath.support.index} component={TicketList} />
                            <Route path={RoutePath.QA.questionDetail(':id')} component={QuestionsDetail} />
                            <Route path={RoutePath.QA.index()} component={QuestionsAndAnswers} />
                            <Route
                                path={RoutePath.telephoneAdvisory.detail(':id')}
                                component={TelephoneAdvisoryDetail}
                            />
                            <Route path={RoutePath.telephoneAdvisory.index} component={TelephoneAdvisoryCo} />
                            <Route path={RoutePath.profile.mobile} component={MProfileComp} />
                            <Route path={RoutePath.userProfile} component={UserProfile} />
                            <Route path={'*'}>
                                <Redirect to={RoutePath.dashboard} />
                            </Route>
                        </Switch>
                    </UserPanel>
                )}
                <BottomNavigation active="USER_PROFILE" />
            </Route>
        </Switch>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Vekalapp);
