type routeParam = string | number;
export const __RoutePath = {
    authorization: `/authorization/`,
    judicialFile: {
        index: '/judicial-file/',
        detail: (id: routeParam) => `/judicial-file/${id}/`,
    },
    contracts: {
        index: '/contracts/',
        detail: (id: routeParam) => `/contracts/${id}/`,
    },
    chat: {
        index: '/online-chat/',
        detail: (id: routeParam) => `/online-chat/${id}/`,
    },
    support: {
        index: '/support/',
        create: '/support/new/',
        detail: (id: routeParam) => `/support/${id}/`,
    },
    ticketCreat: '/support/new/',
    ticketDetail: (id: routeParam) => `/support/${id}/`,
    paymentVerify: (id: routeParam) => `/buy/${id}/`,
    mobileProfile: '/mprofile/',
    QA: {
        index: () => `/qa/`,
        questionDetail: (id: routeParam) => `/qa/${id}`,
    },
    profile: {
        mobile: '/m-menu/',
        index: '/lawyerProfile/',
        general_information: '/lawyerProfile/general_information/',
        registration_information: '/lawyerProfile/registration-information/',
        job_information: '/lawyerProfile/job-information/',
        educational_information: '/lawyerProfile/educational-information/',
        lorem_information: '/lawyerProfile/lorem-information/',
        other_information: '/lawyerProfile/other-information/',
    },
    userProfile: '/profile/',
    telephoneAdvisory: {
        index: '/telephone-advisory/',
        detail: (id?: routeParam) => `/telephone-advisory/${id}/`,
    },
    dashboard: '/my-dashboard/',
};
