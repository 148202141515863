import React from 'react';
import './ticketListCard.style.scss';
import { TicketListType } from '../../../../interface';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../data';
import { Button } from 'vekalapp-react-utilities';
import { getPastDiff } from 'scripts/date';
const TicketListCard: React.FC<{ data: TicketListType }> = (props: { data: TicketListType }) => {
    return (
        <div className="vekalapp-ticketing-list-card">
            <div className="card-header">
                <div className="profile">
                    {/* <img src={isPublic ? downloadImage : image} alt="profile" /> */}
                    <div>
                        <h1>
                            <span>{props.data.title}</span>
                            {/* {!isPublic ? <span>{props.data.lawyer?.rate}</span> : null} */}
                        </h1>
                        {/* <p>
                            {isPublic
                                ? 'اوراق قضایی آماده وکالپ'
                                : detectOnlineStatus(
                                      props.data.lawyer?.last_online ? props.data.lawyer.last_online : new Date(),
                                  )}
                        </p> */}
                    </div>
                </div>
                <Link className="d-none d-md-flex" to={RoutePath.support.detail(props.data.id)}>
                    <Button className="pr-4 pl-4" size="medium">
                        مشاهده بیشتر
                    </Button>
                </Link>
            </div>
            <div className="card-row">
                <p className="items">
                    <span className="q-title">وضعیت: </span>
                    <span className="answer primary">{props.data.status === 1 ? 'باز' : 'پایان یافته'}</span>
                </p>
                <p className="items">
                    <span className="q-title">زمان درخواست: </span>
                    <a className="answer">{getPastDiff(props.data.created_at)}</a>
                </p>
            </div>
            <div className="card-row">
                <p className="items description">
                    <span className="q-title">{'شرح درخواست: '}</span>
                    <span className="answer">{props.data.description}</span>
                </p>
            </div>
            <Link className="d-flex d-md-none" to={RoutePath.support.detail(props.data.id)}>
                <Button size="medium">مشاهده بیشتر</Button>
            </Link>
        </div>
    );
};

export default TicketListCard;
