import React, { useEffect } from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './messages.style.scss';
import { MessagesInterface } from './messages.interface';
import temp_profile from '../../../assets/images/default.jpg';

const Chat: React.FC<ConnectedProps<typeof connector> & MessagesInterface> = function (
    props: ConnectedProps<typeof connector> & MessagesInterface,
) {
    return (
        <div className="vekalapp-chat-message">
            <div className={props.message.sender === props.userData?.id ? 'main-message' : 'main-message receiver'}>
                <div className="img-div">
                    <img src={temp_profile} alt="profile" />
                </div>
                <div className={props.message.sender === props.userData?.id ? 'message darker-bg' : 'message'}>
                    {<p className="pm">{props.message.text}</p>}
                    <div className="time-attachment">
                        {props.message.attachment && (
                            <>
                                <p className="f-name">{props.message.attachment.image.split('/')[5]}</p>
                                <a target="_blank" href={props.message?.attachment?.image} rel="noreferrer">
                                    <i className="material-icons">file_download</i>
                                </a>
                                <span className="spacer" />
                            </>
                        )}
                        <p
                            className={
                                props.message.sender !== props.userData?.user_id
                                    ? 'justify-content-end w-100 d-flex chat-time'
                                    : 'justify-content-start w-100 d-flex chat-time'
                            }
                        >
                            {new Date(props.message.created_at).getHours() +
                                ':' +
                                new Date(props.message.created_at).getMinutes()}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Chat);
