import React, { useEffect, useRef, useState } from 'react';
import { ContractType, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './contractsDetail.style.scss';
import { Breadcrumb, Button } from 'vekalapp-react-utilities';
import { Link, useHistory, useParams } from 'react-router-dom';
import { API, RoutePath } from '../../../data';
import { get, responseValidator } from '../../../scripts/api';
import { toast } from 'react-toastify';
import altImage from 'assets/images/default.jpg';
import ENV from 'env.json';
import emptyStateIMG from 'assets/images/277.svg';
import downloadImage from 'assets/images/fileIcon.png';
import { detectOnlineStatus, getPastDiff } from 'scripts/date';
import { getContractTypeTittle } from '../contracts.script';

const ContractDetail: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const { id } = useParams<any>();
    const [file, setFile] = useState<ContractType | undefined>(undefined);
    const history = useHistory();

    useEffect(() => {
        get<ContractType>(API.contracts.detail(id)).then((res) => {
            if (responseValidator(res.status) && res.data) {
                console.log(res.data);
                setFile(res.data);
            } else {
                toast.error('خطایی رخ داده است');
            }
        });
    }, []);

    if (!file) return <div className="vapp-loading-element" />;

    const image = file.lawyer?.profile_image.image ? file.lawyer?.profile_image.image : altImage;
    const name =
        file.lawyer?.user.first_name.length || file.lawyer?.user.last_name.length
            ? [file.lawyer.user.first_name.length, file.lawyer.user.last_name.length].join(' ')
            : 'بدون نام';
    const isPublic = !file.lawyer && file.parent;

    return (
        <div className="vekalapp-judicial-file-detail">
            <div className="page-header">
                <div>
                    <p className="title">سفارش ها</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.contracts.index} key="2">
                                قرارداد ها
                            </Link>,
                            <Link to={'#'} key="3">
                                جزئیات درخواست
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
            </div>
            <div className="judicial-detail-content">
                <div className="card-row">
                    <p className="items">
                        <span className="q-title">نوع قرارداد: </span>
                        <span className="answer primary">{getContractTypeTittle(file.type)}</span>
                    </p>
                    <p className="items">
                        <span className="q-title">زمان درخواست: </span>
                        <span className="answer">{getPastDiff(file.created_at)}</span>
                    </p>
                </div>
                {file.attachments.length ? (
                    <div className="card-row">
                        <p className="items">
                            <span className="q-title">پیوست: </span>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                download
                                href={file.attachments[0].image}
                                className="answer"
                            >
                                {file.attachments[0].alt ? file.attachments[0].alt : 'دانلود'}
                            </a>
                        </p>
                    </div>
                ) : null}
                <div className="card-row">
                    <p className="items">
                        <span className="q-title">شرح درخواست: </span>
                        <span className="answer">{file.description}</span>
                    </p>
                </div>
            </div>
            <div className="judicial-file-response">
                <div className="card-header">
                    <div className="profile">
                        <img src={isPublic ? downloadImage : image} alt="profile" />
                        <div>
                            <h1>
                                <span>{isPublic ? file.title : name}</span>
                                {!isPublic ? <span>{file.lawyer?.rate}</span> : null}
                            </h1>
                            <p>
                                {isPublic
                                    ? 'قرارداد آماده وکالپ'
                                    : detectOnlineStatus(
                                          file.lawyer?.last_online ? file.lawyer.last_online : new Date(),
                                      )}
                            </p>
                        </div>
                    </div>
                    {!isPublic ? (
                        <a
                            className="d-none d-md-flex"
                            target="_blank"
                            rel="noreferrer"
                            href={ENV.main + '/account/lawyer/1'}
                        >
                            <Button size="medium">مشاهده پروفایل حقوقی</Button>
                        </a>
                    ) : null}
                </div>
                {file.lawyer_description || file.lawyer_attachments.length ? (
                    <div className="lawyer-content">
                        {file.lawyer_description ? (
                            <>
                                <label>{isPublic ? 'توضیحات' : 'توضیحات وکیل:'}</label>
                                <div
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: file.lawyer_description }}
                                ></div>
                            </>
                        ) : null}
                        {file.lawyer_attachments.length ? (
                            <>
                                <label>{isPublic ? 'پیوست' : 'پیوست وکیل:'}</label>
                                <a rel="noreferrer" target="_blank" download href={file.lawyer_attachments[0].image}>
                                    {file.lawyer_attachments[0].alt ? file.lawyer_attachments[0].alt : 'دانلود'}
                                </a>
                            </>
                        ) : null}
                    </div>
                ) : (
                    <div className="empty-state">
                        <img src={emptyStateIMG} alt="empty state" />
                        <p>درخواست شما در حال بررسی توسط وکیل است</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(ContractDetail);
