import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState, TicketListType, UserData, UserReport } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './dashboard.style.scss';
import { Breadcrumb, Button } from 'vekalapp-react-utilities';
import { Link, useHistory } from 'react-router-dom';
import { API, RoutePath } from '../../data';
import profileDefault from '../../assets/images/default.jpg';
import LatestMessages from './latestMessages/latestMessages.index';
import LatestTickets from './latestTickets/latestTickets.index';
import { get, responseValidator } from '../../scripts/api';
import { toast } from 'react-toastify';

const MyDashboard: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    useEffect(() => {
        document.title = 'وکالپ | میز کار من';
        getLatestTickets();
        getLatestMessages();
        getUserReport();
    }, []);

    const [tickets, setTickets] = useState<TicketListType[]>([]);
    const [item, setItems] = useState<UserData[]>([]);
    const [report, setReport] = useState<UserReport>();
    const history = useHistory();

    function getLatestMessages() {
        get<any>(API.chat.userInfo, { page: 1, page_size: 6 }).then((res) => {
            if (responseValidator(res.status) && res.data) {
                if (res.data.users_allow_to_chat) {
                    setItems(res.data.users_allow_to_chat);
                }
            } else toast.error('خطایی رخ داده است');
        });
    }

    function getLatestTickets() {
        get<Pagination<TicketListType>>(API.ticketing.ticketList, { page: 1, page_size: 3 }).then((res) => {
            if (responseValidator(res.status) && res.data) {
                setTickets(res.data?.data);
            } else toast.error('خطایی رخ داده است');
        });
    }

    function getUserReport() {
        if (props.userData) {
            get<UserReport>(API.account.report(props.userData.id)).then((res) => {
                if (responseValidator(res.status) && res.data) {
                    console.log(res.data);
                    setReport(res.data);
                } else toast.error('خطایی رخ داده است');
            });
        }
    }

    return (
        <div className="vekalapp-user-dashboard-page">
            <div className="page-header">
                <div>
                    <p className="title">میز کار من</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.dashboard} key="2">
                                میز کار من
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
            </div>
            <div className="banner-info">
                <img src={props.userData?.avatar ? props.userData.avatar : profileDefault} alt="profile-image" />
                <div style={{ flex: 1 }} className="items">
                    <p className="title">نام و نام خانوادگی: </p>
                    <p className="val">مهدی صادقی</p>
                </div>
                <div className="items">
                    <p className="title">شماره تماس: </p>
                    <p className="val">09123546789</p>
                </div>
            </div>
            <div className="services">
                <p>خدمات حقوقی</p>
                <div className="service-list">
                    <div className="list-item">
                        <div className="icon-div">
                            <i className="material-icons-outlined">description</i>
                        </div>
                        <p className="counts">{report ? report.created_contractual_setting : 0}</p>
                        <p className="title">تنظیم قرارداد</p>
                        <Button type="outlined-accentBlue" color="lightAccentBlue">
                            ثبت قرارداد جدید
                        </Button>
                    </div>
                    <div className="list-item">
                        <div className="icon-div">
                            <i className="material-icons-outlined">folder</i>
                        </div>
                        <p className="counts">{report ? report.created_judicial_file : 0}</p>
                        <p className="title">تنظیم اوراق قضایی</p>
                        <Button type="outlined-accentBlue" color="lightAccentBlue">
                            ثبت اوراق قضایی جدید
                        </Button>
                    </div>
                    <div className="list-item">
                        <div className="icon-div">
                            <i className="material-icons-outlined vertical-revert">article</i>
                        </div>
                        <p className="counts">{report ? report.created_question : 0}</p>
                        <p className="title">پرسش و پاسخ</p>
                        <Button type="outlined-accentBlue" color="lightAccentBlue">
                            ثبت پرسش جدید
                        </Button>
                    </div>
                    <div className="list-item">
                        <div className="icon-div">
                            <i className="material-icons-outlined">call</i>
                        </div>
                        <p className="counts">{report ? report.created_chat : 0}</p>
                        <p className="title">مشاوره تلفنی</p>
                        <Button type="outlined-accentBlue" color="lightAccentBlue">
                            مشاوره تلفنی جدید
                        </Button>
                    </div>
                </div>
            </div>
            {(item || tickets) && (
                <div className="latest-messages-tickets">
                    {item && item.length > 0 && (
                        <div style={{ marginLeft: tickets && tickets.length > 0 ? 20 : 0 }} className="messages">
                            <p className="title">آخرین پیام ها</p>
                            <LatestMessages messages={item} />
                        </div>
                    )}
                    {tickets && tickets.length > 0 && (
                        <div className="tickets">
                            <div className="header">
                                <p className="title">آخرین تیکت های پشتیبانی</p>
                                <span className="spacer" />
                                <Link to={RoutePath.support.create} className="add-ticket">
                                    <i className="material-icons">add</i>تیکت جدید
                                </Link>
                            </div>
                            <LatestTickets tickets={tickets} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(MyDashboard);
