import React, { useEffect, useRef, useState } from 'react';
import { Message, Pagination, ReduxState, UserData } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import { Breadcrumb, Button, TextInput } from 'vekalapp-react-utilities';
import { Link, useHistory, useParams } from 'react-router-dom';
import './onlineChat.style.scss';
import temp_profile from 'assets/images/default.jpg';
import Chat from './messages/messages.index';
import { get, responseValidator, upload } from '../../scripts/api';
import { API, RoutePath } from '../../data';
import { toast } from 'react-toastify';
import { WSCommunication } from '../../scripts/WebSocket';
import { __Attachment } from '../../interface/vekalapp';
import ENV from 'env.json';

const OnlineChat: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [users_allow, setUsersAllow] = useState<UserData[]>([]);
    const [users_allowFiltered, setUsersAllowFiltered] = useState<UserData[]>(users_allow);
    const history = useHistory<any>();
    const { id } = useParams<any>();
    const [receiverId, setReceiverId] = useState<number>();
    const [socket, setSocket] = useState<WSCommunication>();
    const [message, setMessage] = useState<string>();
    const messageRef = useRef<any>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [search, setSearch] = useState<string>('');
    const fileRef = useRef<any>();
    const messagesRef = useRef<any>();
    const [progressBar, setProgressBar] = useState(0);
    const counter = 0;
    const [isUploading, setIsUploading] = useState(false);
    const [uploaded, setIsUploaded] = useState(false);
    const [fileNames, setFileNames] = useState<string>();
    const uploadTools = useRef<any>();
    const [file, setFiles] = useState<__Attachment[]>([]);
    const [attachmentId, setAttachmentId] = useState<number>();

    useEffect(() => {
        const socket = new WSCommunication((e) => setSocket(e));
        const id = socket.subscribe('message', (e) => console.log());
        return () => {
            socket.unSubscribe(id);
            socket.close();
        };
    }, []);

    useEffect(() => {
        messagesRef.current = [...messages];
    }, [messages]);

    useEffect(() => {
        getMyInfo();
    }, []);

    useEffect(() => {
        socket?.subscribe<Message>(
            'message',
            (e) => {
                if (e) {
                    const data = JSON.parse(JSON.stringify(messagesRef.current));
                    setMessages([...data, e]);
                }
                setMessage(undefined);
            },
            (e) => {
                if (e) {
                    toast.error('ارسال پیام با خطا مواجه شد');
                }
            },
        );
    }, [socket]);

    function getOldMessages(id: number) {
        setMessages([]);
        get<Pagination<Message>>(API.chat.messages(id), { page: 1, page_size: 100000 }).then((res) => {
            if (responseValidator(res.status) && res.data) {
                setMessages(res.data?.data);
            } else toast.error('خطایی رخ داده است');
        });
    }

    function getMyInfo() {
        get<any>(API.chat.userInfo).then((res) => {
            if (responseValidator(res.status) && res.data) {
                if (res.data.users_allow_to_chat) {
                    setUsersAllow(res.data.users_allow_to_chat);
                    setUsersAllowFiltered(res.data.users_allow_to_chat);
                }
            } else toast.error('خطایی رخ داده است');
        });
    }

    function onclickHandler() {
        if (socket && (message || file) && receiverId) {
            socket.send('message', {
                text: message ? message : '',
                receiver_user: receiverId,
                attachment: attachmentId ? attachmentId : undefined,
            });
            setMessage('');
            setIsUploaded(false);
            setFiles([]);
            setFileNames(undefined);
            messageRef.current.scrollTo(0, 0);
        }
    }

    function onSearchHandler(e) {
        setSearch(e);
        const data: UserData[] = JSON.parse(JSON.stringify(users_allow));
        setUsersAllowFiltered([...data.filter((item) => item.username?.toLowerCase().includes(e.toLowerCase()))]);
    }

    useEffect(() => {
        if (id) {
            getOldMessages(id);
        }
        setReceiverId(id);
    }, [id]);

    useEffect(() => {
        document.title = 'وکالپ | مشاوره انلاین';
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onclickHandler();
        }
    };

    function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length !== 0) {
            setIsUploading(true);
            const File = e.target.files;
            const files_promise: Promise<any>[] = [];
            for (let i = 0; i < File.length; i++) {
                files_promise.push(fileUploader(File[i]));
            }
            Promise.all(files_promise).then((res) => {
                setIsUploading(false);
                setIsUploaded(true);
                const returned: __Attachment[] = [];
                res.map((rr) => {
                    if (responseValidator(rr.status) && rr.data) {
                        returned.push(rr.data);
                    } else {
                        toast.error('خطایی رخ داده است');
                    }
                });
                setFiles(returned);
                setAttachmentId(returned[0]?.id);
                setFileNames(returned[0]?.image.split('/').pop());
            });
        }
    }

    function fileUploader(item: File) {
        const promise = new Promise<any>((resolve) => {
            const FILE: any = item;
            const form = new FormData();
            form.append('image', FILE);
            uploadTools.current = upload(API.attachment.chat, form, (e) => {
                if ((e / FILE.size) * 100 > 100) {
                    console.log();
                } else setProgressBar((e / FILE.size + counter) * 100 /*/(divide)*/ /*files?.length*/);
            });
            resolve(uploadTools.current.promise);
        });
        return promise;
    }

    return (
        <div className={`vekalapp-online-chat-page ${id ? 'mobile-chat-detail' : ''}`}>
            <div className="page-header">
                <div>
                    <p className="title">مشاوره تلفنی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.telephoneAdvisory.index} key="2">
                                مشاوره تلفنی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <a className="d-md-none" href={ENV.main + '/chat/'}>
                        <Button>
                            <i className="material-icons">add</i>
                        </Button>
                    </a>
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
                <a className="d-none d-md-flex" href={ENV.main + '/chat/'}>
                    <Button className="pl-4 pr-4">
                        <i className="material-icons ml-2">add</i>
                        <span>ساخت درخواست جدید</span>
                    </Button>
                </a>
            </div>
            <div className="chat-context">
                <div className={receiverId ? 'd-none d-md-flex right-sidebar' : 'right-sidebar'}>
                    <TextInput
                        onChange={onSearchHandler}
                        background={'#fff'}
                        value={search}
                        className="search-field"
                        placeholder={'جستجو...'}
                    />
                    <div className="chat-list">
                        {users_allowFiltered && users_allowFiltered.length === 0 ? (
                            <div className="es-users-allow">
                                <p>کابری برای شروع مکالمه یافت نشد</p>
                            </div>
                        ) : (
                            users_allowFiltered?.map((item, i) => (
                                <div
                                    onClick={() => {
                                        setReceiverId(item.id);
                                        history.push(RoutePath.chat.detail(item.id));
                                        getOldMessages(item.id);
                                    }}
                                    key={i}
                                    className={item.id === Number(id) ? 'items active' : 'items'}
                                >
                                    <img src={temp_profile} alt="profile" />
                                    <div className="chat-detail">
                                        <div className="name-date">
                                            <p className="name">{item.username}</p>
                                            <span className={'spacer'} />
                                            <p className="date-time">
                                                {new Date(item.last_message.created_at).getHours() +
                                                    ':' +
                                                    new Date(item.last_message.created_at).getMinutes()}
                                            </p>
                                        </div>
                                        <div className="brief-message">
                                            <p className="msg">
                                                {item.last_message.text === '' ? '-' : item.last_message.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {receiverId ? (
                    <div className="pvp-chat">
                        <div className="header">
                            <img src={temp_profile} alt="profile" />
                            <div className="name-job">
                                <p className="name">ابوالفضل جهان بخش</p>
                                <p className="job">وکیل پایه یک دادگستری</p>
                            </div>
                            <span className="spacer" />
                        </div>
                        <div className="custom-line" />
                        <div ref={messageRef} className="chat-box">
                            {messages &&
                                messages
                                    .sort((b, a) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                                    .map((msg, index) => (
                                        <>
                                            <Chat key={index} message={msg} />
                                            {index + 1 < messages.length &&
                                                new Date(msg.created_at).getDate() -
                                                    new Date(messages[index + 1].created_at).getDate() !=
                                                    0 && (
                                                    <div className="started-chat-date">
                                                        <p>
                                                            {new Date(
                                                                messages[index + 1]?.created_at,
                                                            ).toLocaleDateString('fa')}
                                                        </p>
                                                    </div>
                                                )}
                                        </>
                                    ))}
                            <div className="started-chat-date">
                                <p>
                                    {messages.length !== 0
                                        ? new Date(
                                              messages.sort(
                                                  (a, b) =>
                                                      new Date(a.created_at).getTime() -
                                                      new Date(b.created_at).getTime(),
                                              )[0].created_at,
                                          ).toLocaleDateString('fa')
                                        : 'مکالمه ای صورت نگرفته است'}
                                </p>
                            </div>
                        </div>
                        {uploaded && (
                            <div className="is-uploaded-attachment">
                                <i className="material-icons">description</i>
                                <p>{fileNames}</p>
                                <span className="spacer" />
                                <i onClick={() => setIsUploaded(false)} className="material-icons">
                                    close
                                </i>
                            </div>
                        )}
                        <div onKeyDown={handleKeyDown} className="footer">
                            <input onChange={onChangeHandler} ref={fileRef} type="file" style={{ display: 'none' }} />
                            <i onClick={() => fileRef.current.click()} className="material-icons-outlined">
                                note_add
                            </i>
                            <i className="material-icons-outlined">mic</i>
                            <TextInput
                                value={message}
                                background={'#f1f2f5'}
                                onChange={(e) => setMessage(e)}
                                placeholder="پیام خود را بنویسید..."
                            />
                            <i className="material-icons-outlined rotate-icon" onClick={onclickHandler}>
                                send
                            </i>
                        </div>
                    </div>
                ) : (
                    <div className="es-start-chat d-none d-md-flex">
                        <p>برای شروع، یکی از مکالمات را انتخاب کنید</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(OnlineChat);
