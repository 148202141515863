import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState, TelephoneAdvisoryType } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './telephoneAdvisory.style.scss';
import { Breadcrumb, Button, PaginationComponent } from 'vekalapp-react-utilities';
import { Link, useHistory } from 'react-router-dom';
import { API, RoutePath } from '../../data';
import { get, responseValidator } from '../../scripts/api';
import { toast } from 'react-toastify';
import TelephoneAdvisoryCard from './telephoneAdvisoryCard/telephoneAdvisoryCard.index';
import emptyStateIMG from 'assets/images/277.svg';
import ENV from 'env.json';

const TelephoneAdvisoryCo: React.FC<ConnectedProps<typeof connector>> = function (
    props: ConnectedProps<typeof connector>,
) {
    const [telephoneAdvisory, setTelephoneAdvisory] = useState<Pagination<TelephoneAdvisoryType>>();
    const [page, setPage] = useState<number>(1);
    const history = useHistory<any>();

    useEffect(() => {
        setTelephoneAdvisory(undefined);
        get<Pagination<TelephoneAdvisoryType>>(API.telephoneAdvisory.index, {
            page: page,
            page_size: 10000,
        }).then((res) => {
            if (responseValidator(res.status) && res.data) {
                console.log(res.data?.data);
                setTelephoneAdvisory(res.data);
            } else {
                toast.error('خطایی رخ داده است');
            }
        });
    }, [page]);

    return (
        <div className="vekalapp-telephone-advisory-list">
            <div className="page-header">
                <div>
                    <p className="title">مشاوره تلفنی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.telephoneAdvisory.index} key="2">
                                مشاوره تلفنی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <a className="d-md-none" href={ENV.main + '/telephone_advisory/'}>
                        <Button>
                            <i className="material-icons">add</i>
                        </Button>
                    </a>
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
                <a className="d-none d-md-flex" href={ENV.main + '/telephone_advisory/'}>
                    <Button className="pl-4 pr-4">
                        <i className="material-icons ml-2">add</i>
                        <span>ساخت درخواست جدید</span>
                    </Button>
                </a>
            </div>
            <div className="judicial-file-list-container">
                {telephoneAdvisory?.data?.map((item) => (
                    <TelephoneAdvisoryCard data={item} key={item.id} />
                ))}
            </div>
            {telephoneAdvisory && telephoneAdvisory.data.length === 0 ? (
                <div className="empty-state">
                    <img src={emptyStateIMG} alt="empty state" />
                    <p>شما تا کنون درخواستی نداشته اید</p>
                </div>
            ) : null}
            {!telephoneAdvisory ? <div className="vapp-loading-element" /> : null}
            {telephoneAdvisory ? (
                <PaginationComponent
                    currentPage={page}
                    pageCount={telephoneAdvisory.num_of_pages}
                    onChange={setPage}
                    isShowArrow={false}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({});
const connector = connect(mapStateToProps);
export default connector(TelephoneAdvisoryCo);
