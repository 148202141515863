import React, { ReactNode } from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './panel.style.scss';
import { NavLink, Route } from 'react-router-dom';
import { BottomNavigation } from 'vekalapp-react-utilities';
import { RoutePath } from '../../data';

const UserPanel: React.FC<ConnectedProps<typeof connector> & { children: ReactNode }> = function (
    props: ConnectedProps<typeof connector> & { children: ReactNode },
) {
    return (
        <div className="vekalapp-user-panel-page">
            <div>
                <div className="side-bar-user-panel">
                    <div className="user-detail">
                        {props.user?.avatar ? (
                            <img src={props.user.avatar} alt={props.user.first_name} />
                        ) : (
                            <button>
                                <i className="material-icons-outlined">person</i>
                            </button>
                        )}
                        <div className="detail">
                            <p className="name">
                                {props.user?.first_name || props.user?.last_name
                                    ? props.user?.first_name + ' ' + props.user?.last_name
                                    : 'کاربر بدون نام'}
                            </p>
                            <p className="role">{props.user?.phone}</p>
                        </div>
                    </div>
                    <NavLink to={RoutePath.dashboard} activeClassName="active" className="items">
                        <i className="material-icons">business_center</i>
                        <p>میز کار من</p>
                    </NavLink>
                    <NavLink to={RoutePath.userProfile} className="items">
                        <i className="material-icons">person</i>
                        <p>اطلاعات من</p>
                    </NavLink>
                    <NavLink to={RoutePath.chat.index} className="items">
                        <i className="material-icons">textsms</i>
                        <p>مشاوره انلاین</p>
                    </NavLink>
                    <NavLink to={RoutePath.telephoneAdvisory.index} className="items">
                        <i className="material-icons">call</i>
                        <p>مشاوره تلفنی</p>
                    </NavLink>
                    <NavLink to={RoutePath.judicialFile.index} className="items">
                        <i className="material-icons">receipt_long</i>
                        <p>اوراق قضایی</p>
                    </NavLink>
                    <NavLink to={RoutePath.contracts.index} className="items">
                        <i className="material-icons">article</i>
                        <p>قراردادها</p>
                    </NavLink>
                    <NavLink to={RoutePath.QA.index} className="items">
                        <i className="material-icons">live_help</i>
                        <p>پرسش پاسخ حقوقی</p>
                    </NavLink>
                    {/* <NavLink to={'/reviews'} className="items">
                        <i className="material-icons">drive_file_rename_outline</i>
                        <p>دیدگاه ها</p>
                    </NavLink> */}
                    <NavLink to={RoutePath.support.index} className="items">
                        <i className="material-icons">headset_mic</i>
                        <p>پشتیبانی</p>
                    </NavLink>
                </div>
                <div className="base-content">{props.children}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    user: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(UserPanel);
