import React, { useEffect, useRef, useState } from 'react';
import { Attachment, City, Pagination, Province, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './userProfile.style.scss';
import { Breadcrumb, Button, SingleDropdown, SingleDropdownOption, TextInput } from 'vekalapp-react-utilities';
import { Link, useHistory } from 'react-router-dom';
import { API, RoutePath } from '../../data';
import defaultPhoto from '../../assets/images/default.jpg';
import { patch, post, responseValidator, upload, form, get } from 'scripts/api';
import { toast } from 'react-toastify';
import { setUserData } from 'redux/actions';
import { __Pagination } from 'interface/general';
const UserProfile: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [firstName, setFirstName] = useState<string | undefined>(props.userData?.first_name);
    const [lastName, setLastName] = useState<string | undefined>(props.userData?.last_name);
    const [city, setCity] = useState<SingleDropdownOption | undefined>(
        props.userData?.city
            ? {
                  title: props.userData?.city,
                  payload: props.userData?.city,
              }
            : undefined,
    );
    const [province, setProvince] = useState<SingleDropdownOption | undefined>(undefined);
    const [gender, setGender] = useState<SingleDropdownOption | undefined>(
        props.userData?.gender
            ? {
                  title:
                      parseInt(props.userData?.gender) == 1
                          ? 'مرد'
                          : parseInt(props.userData?.gender) == 2
                          ? 'زن'
                          : 'غیره',
                  payload: props.userData?.gender,
              }
            : undefined,
    );
    const [email, setEmail] = useState<string | undefined>(props.userData?.email);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const uploadRef = useRef<any>(null);
    const uploadTools = useRef<any>();
    const [provincesOption, setProvincesOption] = useState<SingleDropdownOption[]>([]);
    const [cityOptions, setCityOptions] = useState<SingleDropdownOption[]>([]);
    const [cityDisable, setCityDisable] = useState<boolean>(true);
    const [provinceDisable, setProvinceDisable] = useState<boolean>(!!props.userData?.province);
    const history = useHistory();
    function resetValue() {
        const _userData = props.userData;
        setFirstName(_userData?.first_name);
        setLastName(_userData?.last_name);
        setCity(
            props.userData?.city
                ? {
                      title: props.userData?.city,
                      payload: props.userData?.city,
                  }
                : undefined,
        );
        if (props.userData?.province) {
            const provinceValue = provincesOption.find((item) => item.title == props.userData?.province);
            if (provinceValue) {
                setProvince(provinceValue);
            }
        }
        setGender(
            props.userData?.gender
                ? {
                      title:
                          parseInt(props.userData?.gender) == 1
                              ? 'مرد'
                              : parseInt(props.userData?.gender) == 2
                              ? 'زن'
                              : 'غیره',
                      payload: props.userData?.gender,
                  }
                : undefined,
        );
        setEmail(props.userData?.email);
    }

    function uploadHandler(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length !== 0) {
            setIsUploading(true);
            const File = e.target.files;
            const files_promise: Promise<any> = new Promise<any>((resolve) => {
                const FILE: any = File[0];
                const form = new FormData();
                form.append('avatar', FILE);
                uploadTools.current = upload(
                    API.account.userData,
                    form,
                    (e) => {
                        console.log(e);
                    },
                    'put',
                );
                resolve(uploadTools.current.promise);
            });
            files_promise.then((res) => {
                setIsUploading(false);
                console.log(res);
                if (responseValidator(res.status) && res.data) {
                    console.log(res.data);
                    toast.success('تغییرات با موفقیت اعمال شد.');
                    props.dispatch(setUserData(res.data));
                } else {
                    toast.error('خطایی رخ داده است');
                }
            });
        }
    }
    function submitHandler(_avatar?: Attachment) {
        const _form = new FormData();
        if (firstName) _form.append('first_name', firstName);
        if (lastName) _form.append('last_name', lastName);
        if (email) _form.append('email', email);
        if (gender && gender.payload) _form.append('gender', gender.payload.toString());
        if (city && city.payload) _form.append('city', city.title);
        if (province && province.payload) _form.append('province', province.title);
        return new Promise((resolve) => {
            form(API.account.userData, _form, 'PATCH').then((res) => {
                resolve(true);
                if (responseValidator(res.status) && res.data) {
                    toast.success('تغییرات با موفقیت اعمال شد.');
                    props.dispatch(setUserData(res.data));
                } else {
                    toast.error('خطایی رخ داده است');
                }
            });
        });
    }

    //get Province
    useEffect(() => {
        get<Pagination<Province>>(API.location.province, { page: 1, page_size: 10000 }).then((res) => {
            if (responseValidator(res.status) && res.data && res.data.data) {
                const temp: SingleDropdownOption[] = [];
                res.data.data.map((item) => temp.push({ title: item.name, payload: item.id }));
                setProvincesOption(temp);
                if (props.userData?.province) {
                    const provinceValue = res.data.data.find((item) => item.name == props.userData?.province);
                    if (provinceValue) {
                        setProvince({ title: provinceValue.name, payload: provinceValue.id });
                        setProvinceDisable(false);
                    }
                }
            } else {
                toast.error('خطایی رخ داده است.');
            }
        });
    }, []);

    //get City
    useEffect(() => {
        console.log(props.userData?.province, province?.title);
        if (province && props.userData?.province != province?.title) setCity({ title: 'null', payload: '-1' });
        setCityDisable(true);
        console.log(province);
        if (province)
            get<Pagination<City>>(API.location.city, { page: 1, page_size: 10000, province: province?.payload }).then(
                (res) => {
                    if (responseValidator(res.status) && res.data && res.data.data) {
                        setCityDisable(false);
                        const temp: SingleDropdownOption[] = [];
                        res.data.data.map((item) => temp.push({ title: item.name, payload: item.id }));
                        setCityOptions(temp);
                    } else {
                        toast.error('خطایی رخ داده است.');
                    }
                },
            );
    }, [province]);
    return (
        <div className="vekalapp-user-profile-page">
            <div className="page-header">
                <div>
                    <p className="title">اطلاعات من</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.telephoneAdvisory.index} key="2">
                                اطلاعات من
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
            </div>
            <div className="user-profile-container">
                <div className="personal-card">
                    <input accept={'image/*'} type="file" className="d-none" ref={uploadRef} onChange={uploadHandler} />
                    <div className="image-container">
                        <img src={props.userData?.avatar ? props.userData.avatar : defaultPhoto} alt="profile-pic" />
                        {isUploading && (
                            <div className="loading">
                                <i className="cfi cfi-loader"></i>
                            </div>
                        )}
                    </div>
                    <button onClick={() => uploadRef.current.click()} className="change-pic-btn">
                        <i className="material-icons-outlined">photo_camera</i>
                        <p>تغییر تصویر</p>
                    </button>
                    <h4>
                        {[props.userData?.first_name, props.userData?.last_name].join(' ') != ' '
                            ? [props.userData?.first_name, props.userData?.last_name].join(' ')
                            : 'بدون نام'}
                    </h4>

                    {[props.userData?.city, props.userData?.province].join(',') != ',' && (
                        <h6>{[props.userData?.city, props.userData?.province].join(' , ')} </h6>
                    )}

                    <h6>{props.userData?.phone}</h6>
                    <h6>
                        جنسیت :{' '}
                        {props.userData?.gender
                            ? parseInt(props.userData.gender) == 1
                                ? 'مرد'
                                : parseInt(props.userData.gender) == 2
                                ? 'زن'
                                : 'غیره'
                            : 'ثبت نشده'}
                    </h6>
                    {props.userData?.email && <h6>{props.userData.email} </h6>}
                </div>
                <div className="form-container">
                    <div className="my-row">
                        <TextInput
                            value={firstName}
                            onChange={(e) => setFirstName(e)}
                            label="نام"
                            placeholder="نام خود را وارد کنید"
                        />
                        <TextInput
                            value={lastName}
                            onChange={(e) => setLastName(e)}
                            label="نام خانوادگی"
                            placeholder="نام خانوادگی خود را وارد کنید"
                        />
                    </div>
                    <div className="my-row">
                        <SingleDropdown
                            value={province}
                            onChange={(e) => setProvince(e)}
                            options={provincesOption}
                            label="استان"
                            placeholder="استان خود را انتخاب کنید"
                            disabled={provinceDisable}
                        />
                        <SingleDropdown
                            value={city}
                            onChange={(e) => setCity(e)}
                            options={cityOptions}
                            disabled={cityDisable}
                            label="شهر"
                            placeholder="شهر خود را انتخاب کنید"
                        />
                    </div>
                    <div className="my-row">
                        <SingleDropdown
                            value={gender}
                            onChange={(e) => setGender(e)}
                            options={[
                                { title: 'مرد', payload: 1 },
                                { title: 'زن', payload: 2 },
                                { title: 'غیره', payload: 3 },
                            ]}
                            label="جسنیت"
                            placeholder="جنسیت خود را انتخاب کنید"
                        />
                        <TextInput
                            value={email}
                            onChange={(e) => setEmail(e)}
                            label="ایمیل"
                            type="email"
                            placeholder="ایمیل خود را وارد کنید"
                        />
                    </div>
                    <div className="my-row">
                        <Button onClick={resetValue} type="outlined">
                            لغو تغییرات
                        </Button>
                        <Button onClick={submitHandler}>ثبت تغییرات</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    userData: state.userData,
});
const connector = connect(mapStateToProps);
export default connector(UserProfile);
