import { API, RoutePath } from 'data';
import { ReduxState } from 'interface';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get, post, responseValidator } from 'scripts/api';

const BuyComp: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const history = useHistory();
    const { key } = useParams<any>();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const authority = urlParams.get('Authority');
        const status = urlParams.get('Status');
        if (authority && status === 'OK') {
            get(API.account.paymentVerify(authority), {}).then((res) => {
                if (responseValidator(res.status) && res.data) {
                    toast.success('پرداخت با موفقیت انجام شد');
                    if (key === 'judicial_file') {
                        history.replace(RoutePath.judicialFile.index);
                    } else if (key === 'chat') {
                        history.replace(RoutePath.chat.index);
                    } else if (key === 'contractual_setting') {
                        history.replace(RoutePath.contracts.index);
                    } else if (key === 'telephone_advisory') {
                        history.replace(RoutePath.telephoneAdvisory.index);
                    }
                } else {
                    errorHandling();
                }
            });
        } else errorHandling();
    }, []);

    function errorHandling() {
        toast.error('پرداخت با خطایی مواجه شد. لطفا مجددا تلاش نمایید.');
        history.replace('/');
    }

    return (
        <div className="vekalapp-mobile-profile d-md-none">
            <p>لطفا منتظر بمانید</p>
        </div>
    );
};
const mapStateToProps = (state: ReduxState) => ({
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(BuyComp);
