export function getContractTypeTittle(type: number): string {
    const typeOptions = [
        { title: 'اجاره', payload: 1 },
        { title: 'سرمایه گذاری', payload: 2 },
        { title: 'استخدام و کاریابی', payload: 3 },
        { title: 'جعاله', payload: 4 },
        { title: 'پیمانکاری', payload: 5 },
        { title: 'صلح نامه', payload: 6 },
        { title: 'خدمات رایانه ای و موبایل', payload: 7 },
        { title: 'کارگزاری', payload: 8 },
        { title: 'فروش کالا', payload: 9 },
        { title: 'خودرو', payload: 10 },
        { title: 'نمایندگی', payload: 11 },
        { title: 'فروش مسکونی', payload: 12 },
        { title: 'موتورسیکلت', payload: 13 },
        { title: 'مجموعه قرارداد', payload: 14 },
        { title: 'پیوست قرارداد', payload: 15 },
    ];
    const item = typeOptions.find((i) => i.payload === type);
    return item ? item.title : '';
}
