import React from 'react';
import { ReduxState, TelephoneAdvisoryConflictEnum } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './telephoneAdvisoryCard.style.scss';
import { TelephoneAdvisoryProp } from './telephoneAdvisoryCard.interface';
import { Button } from 'vekalapp-react-utilities';
import altImage from 'assets/images/default.jpg';
import { Link } from 'react-router-dom';
import { RoutePath } from 'data';
import { detectOnlineStatus, getPastDiff } from 'scripts/date';

const TelephoneAdvisoryCard: React.FC<ConnectedProps<typeof connector> & TelephoneAdvisoryProp> = function (
    props: ConnectedProps<typeof connector> & TelephoneAdvisoryProp,
) {
    const name =
        props.data.lawyer.user.first_name.length || props.data.lawyer.user.last_name.length
            ? [props.data.lawyer.user.first_name.length, props.data.lawyer.user.last_name.length].join(' ')
            : 'بدون نام';
    const image = props.data.lawyer?.profile_image?.image ? props.data.lawyer.profile_image?.image : altImage;

    function handleAdvisoryState() {
        if (!props.data?.start) return 'در انتظار تنظیم زمان تماس از سوی وکیل';
        const start = props.data ? new Date(props.data?.start).getTime() : new Date().getTime();
        const end = props.data ? start + props.data?.duration * 60 * 1000 : new Date().getTime();
        let res = '';
        if (props.data?.start && Date.now() < start) {
            res =
                'زمان مشاوره: ' +
                new Date(start).getHours().toLocaleString('fa') +
                ':' +
                new Date(start).getMinutes().toLocaleString('fa');
        }
        if (props.data?.start && start < Date.now() && Date.now() < end) {
            res = 'بازه برقراری تماس';
        }
        if (
            props.data?.start &&
            start < Date.now() &&
            Date.now() > end &&
            props.data.end_date_conflict !== TelephoneAdvisoryConflictEnum.userConflict &&
            props.data.end_date_conflict !== TelephoneAdvisoryConflictEnum.bothConflict &&
            !props.data.user_end_date
        ) {
            res = 'اتمام بازه برقراری تماس';
        }
        if (
            props.data?.start &&
            start < Date.now() &&
            Date.now() > end &&
            props.data.end_date_conflict !== TelephoneAdvisoryConflictEnum.noConflict &&
            props.data.end_date_conflict !== TelephoneAdvisoryConflictEnum.lawyerConflict
        ) {
            res = 'در حال بررسی توسط پشتیبانی';
        }
        if (
            props.data?.start &&
            start < Date.now() &&
            Date.now() > end &&
            props.data.end_date_conflict !== TelephoneAdvisoryConflictEnum.userConflict &&
            props.data.end_date_conflict !== TelephoneAdvisoryConflictEnum.bothConflict &&
            props.data.user_end_date
        ) {
            res = 'پایان یافته';
        }
        return res;
    }

    return (
        <div className="vekalapp-telephone-advisory-card">
            <div className="card-header">
                <div className="profile">
                    <img src={image} alt="profile" />
                    <div>
                        <h1>
                            <span>{name}</span>
                            <span>{props.data.lawyer.rate}</span>
                        </h1>
                        <p>{detectOnlineStatus(props.data.lawyer.last_online)}</p>
                    </div>
                </div>
                <Link className="d-none d-md-flex" to={RoutePath.telephoneAdvisory.detail(props.data.id)}>
                    <Button className="pr-4 pl-4" size="medium">
                        مشاهده بیشتر
                    </Button>
                </Link>
            </div>
            <div className="card-row">
                <p className="items">
                    <i className="material-icons">event</i>
                    <span className="q-title">تاریخ درخواست: </span>
                    <span className="answer">{getPastDiff(props.data.created_at)}</span>
                </p>
                <p className="items">
                    <i className="material-icons">timer</i>
                    <span className="q-title">طول مشاوره: </span>
                    <a className="answer">{props.data.duration} دقیقه</a>
                </p>
            </div>
            <div className="card-row">
                <p className="items">
                    <i className="material-icons">category</i>
                    <span className="q-title">دسته بندی: </span>
                    <span className="answer">{props.data?.issue_type?.name}</span>
                </p>
                <p className="items">
                    <i className="material-icons">category</i>
                    <span className="q-title">نوع دعوی: </span>
                    <a className="answer">{props.data?.issue_type?.category?.name}</a>
                </p>
            </div>
            <div className="card-row">
                <p className="items">
                    <i className="material-icons">phone</i>
                    <span className="q-title">شماره تلفن: </span>
                    <span className="answer">{props.data.phone}</span>
                </p>
                <p className="items">
                    <i className="material-icons">info</i>
                    <span className="q-title">وضعیت مشاوره: </span>
                    <a className="answer">{handleAdvisoryState()}</a>
                </p>
            </div>
            <Link className="d-flex d-md-none" to={RoutePath.telephoneAdvisory.detail(props.data.id)}>
                <Button size="medium">مشاهده بیشتر</Button>
            </Link>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(TelephoneAdvisoryCard);
