import React, { useEffect, useState } from 'react';
import { ReduxState, TelephoneAdvisoryConflictEnum, TelephoneAdvisoryType } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './detail.style.scss';
import { Breadcrumb, Button, TextInput } from 'vekalapp-react-utilities';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get, post, responseValidator } from '../../../scripts/api';
import { API, RoutePath } from '../../../data';
import altImage from 'assets/images/default.jpg';
import { detectOnlineStatus, getPastDiff } from 'scripts/date';

const TelephoneAdvisoryDetail: React.FC = function () {
    const { id } = useParams<any>();
    const [data, setData] = useState<TelephoneAdvisoryType>();
    const history = useHistory<any>();

    // TODO: add description and end date new logic

    useEffect(() => {
        get<TelephoneAdvisoryType>(API.telephoneAdvisory.detail(id)).then((res) => {
            if (responseValidator(res.status) && res.data) {
                setData(res.data);
            } else {
                toast.error('خطایی رخ داده است');
            }
        });
    }, []);

    const lawyerName =
        data?.lawyer.user.first_name.length || data?.lawyer.user.last_name.length
            ? [data.lawyer.user.first_name.length, data.lawyer.user.last_name.length].join(' ')
            : 'بدون نام';
    const lawyerImage = data?.lawyer?.profile_image?.image ? data?.lawyer.profile_image?.image : altImage;

    const start = data ? new Date(data?.start).getTime() : new Date().getTime();
    const end = data ? start + data?.duration * 60 * 1000 : new Date().getTime();
    function handleAdvisoryState() {
        if (!data?.start) return 'در انتظار تنظیم زمان تماس از سوی وکیل';
        let res = '';
        if (data?.start && Date.now() < start) {
            res =
                'زمان مشاوره: ' +
                new Date(start).getHours().toLocaleString('fa') +
                ':' +
                new Date(start).getMinutes().toLocaleString('fa');
        }
        if (data?.start && start < Date.now() && Date.now() < end) {
            res = 'بازه برقراری تماس';
        }
        if (
            data?.start &&
            start < Date.now() &&
            Date.now() > end &&
            data.end_date_conflict !== TelephoneAdvisoryConflictEnum.userConflict &&
            data.end_date_conflict !== TelephoneAdvisoryConflictEnum.bothConflict &&
            !data.user_end_date
        ) {
            res = 'اتمام بازه برقراری تماس';
        }
        if (
            data?.start &&
            start < Date.now() &&
            Date.now() > end &&
            data.end_date_conflict !== TelephoneAdvisoryConflictEnum.noConflict &&
            data.end_date_conflict !== TelephoneAdvisoryConflictEnum.lawyerConflict
        ) {
            res = 'در حال بررسی توسط پشتیبانی';
        }
        if (
            data?.start &&
            start < Date.now() &&
            Date.now() > end &&
            data.end_date_conflict !== TelephoneAdvisoryConflictEnum.userConflict &&
            data.end_date_conflict !== TelephoneAdvisoryConflictEnum.bothConflict &&
            data.user_end_date
        ) {
            res = 'پایان یافته';
        }
        return res;
    }

    function onEndAdvisoryHandler() {
        return new Promise<any>((resolve) => {
            post<TelephoneAdvisoryType>(API.telephoneAdvisory.close(id), {}).then((res) => {
                resolve(true);
                if (responseValidator(res.status) && res.data) {
                    setData(res.data);
                } else {
                    toast.error('خطایی رخ داده است');
                }
            });
        });
    }

    function onEndAdvisoryReject() {
        return new Promise<any>((resolve) => {
            post<TelephoneAdvisoryType>(API.telephoneAdvisory.reject(id), {}).then((res) => {
                resolve(true);
                if (responseValidator(res.status) && res.data) {
                    setData(res.data);
                } else {
                    toast.error('خطایی رخ داده است');
                }
            });
        });
    }

    if (!data) return <div className="vapp-loading-element" />;

    return (
        <div className="vekalapp-telephone-advisory-detail">
            <div className="page-header">
                <div>
                    <p className="title">مشاوره تلفنی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.telephoneAdvisory.index} key="2">
                                مشاوره تلفنی
                            </Link>,
                            <Link to={'#'} key="3">
                                جزئیات مشاوره تلفنی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
            </div>
            <div className="telephone-advisory-data">
                <div className="card-header">
                    <div className="profile">
                        <img src={lawyerImage} alt="profile" />
                        <div>
                            <h1>
                                <span>{lawyerName}</span>
                                <span>{data?.lawyer.rate}</span>
                            </h1>
                            <p>{detectOnlineStatus(data.lawyer.last_online)}</p>
                        </div>
                    </div>
                </div>
                <div className="card-row">
                    <p className="items">
                        <i className="material-icons">event</i>
                        <span className="q-title">تاریخ درخواست: </span>
                        <span className="answer">{getPastDiff(data.created_at)}</span>
                    </p>
                    <p className="items">
                        <i className="material-icons">timer</i>
                        <span className="q-title">طول مشاوره: </span>
                        <a className="answer">{data.duration} دقیقه</a>
                    </p>
                </div>
                <div className="card-row">
                    <p className="items">
                        <i className="material-icons">category</i>
                        <span className="q-title">دسته بندی: </span>
                        <span className="answer">{data.issue_type?.name}</span>
                    </p>
                    <p className="items">
                        <i className="material-icons">category</i>
                        <span className="q-title">نوع دعوی: </span>
                        <a className="answer">{data.issue_type?.category.name}</a>
                    </p>
                </div>
                <div className="card-row">
                    <p className="items">
                        <i className="material-icons">phone</i>
                        <span className="q-title">شماره تلفن: </span>
                        <span className="answer">{data.phone}</span>
                    </p>
                    <p className="items">
                        <i className="material-icons">info</i>
                        <span className="q-title">وضعیت مشاوره: </span>
                        <a className="answer">{handleAdvisoryState()}</a>
                    </p>
                </div>
                <div className="card-row">
                    <p className="items description">
                        <i className="material-icons">description</i>
                        <span className="q-title">شرح درخواست: </span>
                        <span className="answer description">{data?.description}</span>
                    </p>
                </div>
                {data.attachments.length ? (
                    <div className="card-row">
                        <p className="items">
                            <i className="material-icons">file_download</i>
                            <span className="q-title">پیوست: </span>
                            <a href={data.attachments[0]?.image} target="_blank" rel="noreferrer" className="answer">
                                {data.attachments[0].alt ? data.attachments[0].alt : 'دانلود'}
                            </a>
                        </p>
                    </div>
                ) : null}
                {data &&
                data?.start &&
                !data.lawyer_end_date &&
                data.end_date_conflict !== TelephoneAdvisoryConflictEnum.userConflict &&
                data.end_date_conflict !== TelephoneAdvisoryConflictEnum.bothConflict &&
                start < Date.now() &&
                Date.now() > end ? (
                    <div className="end-advisory-q">
                        <p>آیا مشاوره شما انجام شده و به پایان رسیده است؟</p>
                        <div className="q-btn">
                            <Button onClick={onEndAdvisoryHandler} className="confirm-reject">
                                بله
                            </Button>
                            <Button onClick={onEndAdvisoryReject} className="confirm-reject" type="outlined">
                                عدم پایان
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default TelephoneAdvisoryDetail;
