import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState, TicketListType } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './ticketCreate.style.scss';
import { Breadcrumb, Button, TextArea, TextInput } from 'vekalapp-react-utilities';
import { post, responseValidator } from '../../../scripts/api';
import { API, RoutePath } from '../../../data';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const TicketCreate: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const history = useHistory();
    function submitHandler() {
        return new Promise((resolve) => {
            post(API.ticketing.ticketList, { title, description }).then((res) => {
                resolve(true);
                if (responseValidator(res.status)) {
                    toast.success('تیکت با موقیت ایجاد شد.');
                    history.push(RoutePath.support.index);
                } else {
                    toast.error('خطایی رخ داده است لطفا دوباره تلاش کنید.');
                }
            });
        });
    }

    return (
        <div className="vekalapp-ticketing-create">
            <div className="page-header">
                <div>
                    <p className="title">پشتیبانی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.support.index} key="2">
                                پشتیبانی
                            </Link>,
                            <Link to={'#'} key="3">
                                ساخت تیکت پشتیبانی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
            </div>
            <div className="form">
                <TextInput
                    placeholder="عنوان تیکت خود را بنویسید..."
                    label="عنوان تیکت"
                    onChange={setTitle}
                    value={title}
                />
                <TextArea
                    onChange={setDescription}
                    value={description}
                    placeholder="شرح تیکت خود را بنویسید..."
                    label="شرح تیکت"
                />
                <Button disabled={!(title && description)} onClick={submitHandler}>
                    ثبت تیکت
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(TicketCreate);
