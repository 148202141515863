import { RoutePath } from 'data';
import { ReduxState } from 'interface';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './mobileProfile.style.scss';
import { ShareModal } from 'vekalapp-react-utilities';

const MProfileComp: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    return (
        <div className="vekalapp-mobile-profile d-md-none">
            {props.userData?.avatar ? (
                <img src={props.userData.avatar} alt={props.userData.first_name} />
            ) : (
                <button>
                    <i className="cfi cfi-user active"></i>
                </button>
            )}
            <h2>
                {props.userData?.first_name || props.userData?.last_name
                    ? props.userData?.first_name + ' ' + props.userData?.last_name
                    : 'کاربر بدون نام'}
            </h2>
            <h3>{props.userData?.phone}</h3>
            <div className="menu-items">
                {/* <a className="items" href="/dashboard">
                    <i className="material-icons">business_center</i>
                    <p>میز کار من</p>
                    <i className="material-icons">arrow_left</i>
                </a> */}
                <NavLink to={RoutePath.userProfile} className="items">
                    <i className="material-icons">person</i>
                    <p>اطلاعات من</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
                <NavLink to={RoutePath.chat.index} className="items">
                    <i className="material-icons">textsms</i>
                    <p>مشاوره آنلاین</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
                <NavLink to={RoutePath.telephoneAdvisory.index} className="items">
                    <i className="material-icons">call</i>
                    <p>مشاوره تلفنی</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
                <NavLink to={RoutePath.judicialFile.index} className="items">
                    <i className="material-icons">receipt_long</i>
                    <p>اوراق قضایی</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
                <NavLink to={RoutePath.contracts.index} className="items">
                    <i className="material-icons">article</i>
                    <p>قراردادها</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
                <NavLink to={RoutePath.QA.index} className="items">
                    <i className="material-icons">live_help</i>
                    <p>پرسش پاسخ حقوقی</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
                {/* <NavLink to={'/reviews'} className="items">
                        <i className="material-icons">drive_file_rename_outline</i>
                        <p>دیدگاه ها</p>
                    </NavLink> */}
                <ShareModal>
                    <div className="items">
                        <i className="material-icons">share</i>
                        <p>اشتراک گذاری</p>
                        <i className="material-icons">arrow_left</i>
                    </div>
                </ShareModal>

                <NavLink to={RoutePath.support.index} className="items">
                    <i className="material-icons">headset_mic</i>
                    <p>پشتیبانی</p>
                    <i className="material-icons">arrow_left</i>
                </NavLink>
            </div>
        </div>
    );
};
const mapStateToProps = (state: ReduxState) => ({
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(MProfileComp);
