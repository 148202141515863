import React from 'react';
import './questionCard.style.scss';
import { __QuestionInterfaceProps } from './questionCard.interface';
import { Button } from 'vekalapp-react-utilities';
import { RoutePath } from '../../../../data';
import { useHistory } from 'react-router-dom';

const QuestionCard: React.FC<__QuestionInterfaceProps> = function (props: __QuestionInterfaceProps) {
    const id = props.data.id;
    const history = useHistory();
    return (
        <div className="question-card-container">
            <h3 className="title">{props.data.title}</h3>
            <p className="text">{props.data.description}</p>
            <div className="question-card-footer">
                <p>
                    {props.data.questionCount
                        ? props.data.questionCount + 'پاسخ ثبت شده تا امروز'
                        : 'هنوز هیچ پاسخنامه ای ثبت نشده است'}{' '}
                </p>
                <Button onClick={() => history.push(RoutePath.QA.questionDetail(id))}>مشاهده پاسخها</Button>
            </div>
        </div>
    );
};

export default QuestionCard;
