import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState, TicketListType } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './ticketList.style.scss';
import { get, responseValidator } from '../../../scripts/api';
import { API, RoutePath } from '../../../data';
import TicketListCard from './card/ticketListCard.index';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, PaginationComponent } from 'vekalapp-react-utilities';
import { Link, useHistory } from 'react-router-dom';
import emptyStateIMG from 'assets/images/277.svg';

const TicketList: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [data, setData] = useState<Pagination<TicketListType>>();
    const [page, setPage] = useState<number>(1);
    const history = useHistory();
    useEffect(() => {
        get<Pagination<TicketListType>>(API.ticketing.ticketList, { page, page_size: 3 }).then((res) => {
            if (responseValidator(res.status) && res.data) {
                setData(res.data);
            } else {
                toast.error('خطایی رخ داده است');
            }
        });
    }, [page]);

    return (
        <div className="vekalapp-ticketing-list">
            <div className="page-header">
                <div>
                    <p className="title">پشتیبانی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.support.index} key="2">
                                پشتیبانی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <Link className="d-md-none" to={RoutePath.support.create}>
                        <Button>
                            <i className="material-icons">add</i>
                        </Button>
                    </Link>
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
                <Link className="d-none d-md-flex" to={RoutePath.support.create}>
                    <Button className="pl-4 pr-4">
                        <i className="material-icons ml-2">add</i>
                        <span>ساخت درخواست جدید</span>
                    </Button>
                </Link>
            </div>
            <div className="ticket-list-container">
                {data?.data.map((item) => (
                    <TicketListCard key={item.id} data={item} />
                ))}
            </div>
            {data && data.data.length === 0 ? (
                <div className="empty-state">
                    <img src={emptyStateIMG} alt="empty state" />
                    <p>شما تا کنون درخواست پشتیبانی نداشته اید</p>
                </div>
            ) : null}
            {!data ? <div className="vapp-loading-element" /> : null}
            {data ? (
                <PaginationComponent
                    currentPage={page}
                    pageCount={data.num_of_pages}
                    onChange={setPage}
                    isShowArrow={false}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(TicketList);
