import React, { useEffect, useState } from 'react';
import { JudicialFile, Pagination, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './judicialFileList.style.scss';
import { Breadcrumb, Button, PaginationComponent } from 'vekalapp-react-utilities';
import { Link, useHistory } from 'react-router-dom';
import { API, RoutePath } from '../../../data';
import JudicialFileCard from '../judicialFileCard/judicialFileCard.index';
import { get, responseValidator } from '../../../scripts/api';
import { toast } from 'react-toastify';
import emptyStateIMG from 'assets/images/277.svg';
import ENV from 'env.json';

const JudicialFileList: React.FC<ConnectedProps<typeof connector>> = function (
    props: ConnectedProps<typeof connector>,
) {
    useEffect(() => {
        document.title = 'وکالپ | اوراق قضايی';
    }, []);

    const [files, setFiles] = useState<Pagination<JudicialFile>>();
    const [page, setPage] = useState<number>(1);
    const history = useHistory();

    function getJudicialFile() {
        setFiles(undefined);
        get<Pagination<JudicialFile>>(API.judicialFile.index, { page, page_size: 3, order: 'newest' }).then((res) => {
            if (responseValidator(res.status) && res.data) {
                setFiles(res.data);
            } else {
                toast.error('خطایی رخ داده است');
            }
        });
    }

    useEffect(() => {
        getJudicialFile();
    }, [page]);

    return (
        <div className="vekalapp-judicial-file-page">
            <div className="page-header">
                <div>
                    <p className="title">اوراق قضایی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.judicialFile.index} key="2">
                                اوراق قضایی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <a className="d-md-none" href={ENV.main + '/judicial_file/'}>
                        <Button>
                            <i className="material-icons">add</i>
                        </Button>
                    </a>
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
                <a className="d-none d-md-flex" href={ENV.main + '/judicial_file/'}>
                    <Button className="pl-4 pr-4">
                        <i className="material-icons ml-2">add</i>
                        <span>ساخت درخواست جدید</span>
                    </Button>
                </a>
            </div>
            <div className="judicial-file-list-container">
                {files?.data.map((item) => (
                    <JudicialFileCard data={item} key={item.id} />
                ))}
            </div>
            {files && files.data.length === 0 ? (
                <div className="empty-state">
                    <img src={emptyStateIMG} alt="empty state" />
                    <p>شما تا کنون درخواستی نداشته اید</p>
                </div>
            ) : null}
            {!files ? <div className="vapp-loading-element" /> : null}
            {files ? (
                <PaginationComponent
                    currentPage={page}
                    pageCount={files.num_of_pages}
                    onChange={setPage}
                    isShowArrow={false}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(JudicialFileList);
