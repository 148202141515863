import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './latestMessages.style.scss';
import { RoutePath } from '../../../data';
import temp_profile from '../../../assets/images/default.jpg';
import { useHistory } from 'react-router-dom';
import { LatestMessagesInterface } from './latestMessages.interface';

const LatestMessages: React.FC<ConnectedProps<typeof connector> & LatestMessagesInterface> = function (
    props: ConnectedProps<typeof connector> & LatestMessagesInterface,
) {
    const history = useHistory<any>();

    return (
        <div className="dashboard-latest-messages">
            {props.messages &&
                props.messages.map((data, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            history.push(RoutePath.chat.detail(data.id));
                        }}
                        className={'items active'}
                    >
                        <img src={temp_profile} alt="profile" />
                        <div className="chat-detail">
                            <div className="name-date">
                                <p className="name">{data.username}</p>
                                <span className={'spacer'} />
                                <p className="date-time">
                                    {new Date(data.last_message.created_at).getHours() +
                                        ':' +
                                        new Date(data.last_message.created_at).getMinutes()}
                                </p>
                            </div>
                            <div className="brief-message">
                                <p className="msg">{data.last_message.text === '' ? '-' : data.last_message.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({});

const connector = connect(mapStateToProps);
export default connector(LatestMessages);
