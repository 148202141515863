import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './latestTickets.style.scss';
import { RoutePath } from '../../../data';
import { useHistory } from 'react-router-dom';
import { LatestTicketsInterface } from './latestTickets.interface';

const LatestTickets: React.FC<ConnectedProps<typeof connector> & LatestTicketsInterface> = function (
    props: ConnectedProps<typeof connector> & LatestTicketsInterface,
) {
    const history = useHistory<any>();
    return (
        <div className="dashboard-latest-tickets">
            {props.tickets &&
                props.tickets.map((data, i) => (
                    <div key={i} className="item">
                        <div className="header-ticket">
                            <p className="title">{data.title}</p>
                            <span className="spacer" />
                            <p className="date-status">
                                تاریخ ثبت تیکت: {new Date(data.created_at).toLocaleDateString('fa')}
                            </p>
                        </div>
                        <div className="footer">
                            <div className="desc">
                                <p>{data.description}</p>{' '}
                                <span
                                    onClick={() => {
                                        history.push(RoutePath.support.detail(data.id));
                                    }}
                                >
                                    مشاهده بیشتر ...
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({});

const connector = connect(mapStateToProps);
export default connector(LatestTickets);
