import React from 'react';
import './answerCard.style.scss';
import alt_image from '../../../../assets/images/default.jpg';
import { __AnswerInterfaceProps } from './answerCard.interface';
import { RoutePath } from '../../../../data';
import { Button } from 'vekalapp-react-utilities';
import { getPastDiff } from 'scripts/date';
import ENV from 'env.json';

const AnswerCard: React.FC<__AnswerInterfaceProps> = function (props: __AnswerInterfaceProps) {
    return (
        <div className="answer-card-container">
            <div className="answer-card-header">
                <div className="lawyer-container">
                    <img
                        src={
                            props.data.creator?.profile_image.image
                                ? props.data.creator?.profile_image.image
                                : alt_image
                        }
                        alt="profile"
                    />
                    <div>
                        <div>
                            <h4>
                                {(props.data.creator?.user.first_name &&
                                    props.data.creator.user.first_name.length > 0) ||
                                (props.data.creator?.user.first_name && props.data.creator.user.first_name.length > 0)
                                    ? props.data.creator?.user.first_name + ' ' + props.data.creator.user.last_name
                                    : 'بدون نام'}
                            </h4>
                            <span>{props.data.creator?.rate}</span>
                        </div>
                        <p>
                            {'پاسخ داده شده در ' +
                                getPastDiff(props.data.created_at ? props.data.created_at : new Date())}
                        </p>
                    </div>
                </div>
                <a
                    href={ENV.main + '/account/lawyer/' + props.data.creator?.id + '/'}
                    target="_blank"
                    rel="noreferrer"
                    type="outlined"
                    className="lawyer-profile-button d-none d-md-flex"
                >
                    مشاهده پروفایل حقوقی
                </a>
            </div>
            <div className="answer-card-body">{props.data.description}</div>
        </div>
    );
};

export default AnswerCard;
