import { __User } from './register';
import { User } from './index';

// import LawyerProfile from '../vekalapp/profile/profile.index';

export interface test {}

export interface __JudicialFile {
    id: number;
    type: number;
    description: string;
    lawyer_description: string;
    is_public: boolean;
    creator: number;
    lawyer?: __LawyerType;
    city: number;
    province: number;
    parent: number;
    price: number;
    title: string;
    lawyer_attachments: __Attachment[];
    attachments: __Attachment[];
    created_at: string;
}

export interface __ContractType {
    id: number;
    type: number;
    description: string;
    lawyer_description: string;
    is_public: boolean;
    creator: number;
    lawyer?: __LawyerType;
    city: number;
    province: number;
    parent: number;
    price: number;
    title: string;
    lawyer_attachments: __Attachment[];
    attachments: __Attachment[];
    created_at: string;
}

export interface __TicketList {
    created_at: string;
    description: string;
    id: number;
    status: number;
    title: string;
}

export interface __TicketMessages {
    id: number;
    created_at: string;
    text: string;
    creator: number;
    attachment?: string;
}

export interface __TicketDetail {
    id: number;
    creator: __User;
    messages: __TicketMessages[];
    created_at: string;
    title: string;
    description: string;
    status: number;
}

export interface __MessageType {
    id: number;
    attachment: __Attachment | null;
    text: string;
    read: boolean | null;
    sender: number;
    receiver_user: number;
    reply_to: number | null;
    created_at: string;
}

export interface __Attachment {
    id: number;
    alt: string;
    image: string;
    creator?: number;
}

export interface __LawyerType {
    modified_at: string;
    phone: string;
    is_template: boolean;
    rate: number;
    license_number: string;
    last_online: string;
    licence_center: number; //[ 1 <= مرکز وکلا, 2 <= کانون وکلا ]
    licence_expire_date: string;
    grade: number; //[ 1 <= پروانه کارآموزی, 2 <= وکیل پایه یک دادگستری, 3 <= وکیل پایه دو دادگستری ]
    type: number; // [ 1 => عمومی, 2 => متخصص ]
    pro_fields: string;
    bachelors_field: string;
    bachelor_university_type: number; //[ 1 => سراری, 2 => آزاد ]
    bachelor_university_name: string;
    master_field: string;
    master_university_type: number; //[ 1 => سراری, 2 => آزاد ]
    master_university_name: string;
    phd_field: string;
    phd_university_type: number;
    phd_university_name: string;
    office_address: string;
    office_landline_number: string;
    bio: string;
    research_history: string;
    request_for_case: boolean;
    can_provide_judicial_files_service: boolean;
    can_provide_contractual_setting_service: boolean;
    can_provide_online_chat_service: boolean;
    online_chat_cost: number;
    can_provide_telephone_advisory_service: boolean;
    telephone_advisory_15_min_cost: number;
    telephone_advisory_45_min_cost: number;
    telephone_advisory_60_min_cost: number;
    telephone_advisory_30_min_cost: number;
    telephone_advisory_number: string;
    city: number;
    job_city: number;
    bachelor_university_city: number;
    master_university_city: number;
    phd_university_city: number;
    last_education_document: number;
    profile_image: __Attachment;
    id_card: number;
    licence_image: __Attachment;
    licence_books_first_page: number;
    licence_books_extend_page: number;
    approval_status: string;
    user: User;
}

export interface __City {
    id: number;
    name: string;
    province: number;
}

export interface __Province {
    id: number;
    name: string;
}

export interface __TelephoneAdvisoryType {
    id: number;
    creator: User;
    lawyer: __LawyerType;
    issue_type: __ProfiledType;
    description: string;
    start: string;
    created_at: string;
    duration: number;
    end_date_conflict: __TelephoneAdvisoryConflictEnum;
    phone: string;
    lawyer_end_date: string;
    user_end_date: string;
    attachments: __Attachment[];
}

export enum __TelephoneAdvisoryConflictEnum {
    noConflict = 1,
    userConflict = 3,
    lawyerConflict = 2,
    bothConflict = 4,
}

export interface __ProfiledType {
    id: number;
    name: string;
    category: __ProfiledCategoryType;
}

export interface __ProfiledCategoryType {
    id: number;
    name: string;
}

export interface __UserReport {
    user: string;
    created_contractual_setting: string;
    created_judicial_file: string;
    created_chat: string;
    created_telephone_advisory: string;
    created_question: string;
    created_answer: string;
    created_ticket: string;
}
