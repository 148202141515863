type apiParams = string | number;
import ENV from 'env.json';

export const __API = {
    account: {
        userData: ENV.auth + '/api/account/me/',
        logout: ENV.auth + '/api/account/user/logout/',
        paymentVerify: (authority: apiParams) => `${ENV.api}/account/payment/${authority}/verify/`,
        report: (id: apiParams) => `${ENV.api}/account/users/${id}/report/`,
    },
    judicialFile: {
        index: ENV.api + '/judicial_file/judicial_file/',
        detail: (id?: apiParams) => `${ENV.api}/judicial_file/judicial_file/${id}/`,
    },
    contracts: {
        index: ENV.api + '/contractual_setting/contractual_setting/',
        detail: (id?: apiParams) => `${ENV.api}/contractual_setting/contractual_setting/${id}/`,
    },
    ticketing: {
        ticketList: ENV.api + '/ticketing/ticket/',
        ticketDetail: (id: apiParams) => `${ENV.api}/ticketing/ticket/${id}/`,
        ticketMessage: ENV.api + '/ticketing/ticket_message/',
    },
    chat: {
        userInfo: ENV.chat + '/account/user/my_info/',
        messages: (id: apiParams) => `${ENV.chat}/chat/message/${id}/`,
    },
    QA: {
        questionList: ENV.api + '/QA/question/',
        questionDetail: (id: apiParams) => `${ENV.api}/QA/question/${id}`,
        questionAnswers: (id: apiParams) => `${ENV.api}/QA/answer/?question=${id}`,
    },
    profile: {
        index: ENV.api + '/account/lawyer/my_profile',
    },
    location: {
        city: ENV.api + '/location/cities/',
        province: ENV.api + '/location/provinces/',
    },
    attachment: {
        index: ENV.api + '/core/file_attachment/',
        imageAttachment: ENV.api + '/core/image_attachment/',
        chat: ENV.chat + '/chat/file_attachment/',
    },
    telephoneAdvisory: {
        index: ENV.api + '/telephone_advisory/',
        detail: (id: apiParams) => `${ENV.api}/telephone_advisory/${id}/`,
        close: (id?: apiParams) => `${ENV.api}/telephone_advisory/${id}/user_close_conversation/`,
        reject: (id?: apiParams) => `${ENV.api}/telephone_advisory/${id}/user_objection/`,
    },
};
