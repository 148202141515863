import { Attachment, Message } from './index';

export interface __Tokens {
    access_token: string;
    //expire_date: string;
    //refresh: string;
}

export enum __AuthStatus {
    valid = 'valid',
    pending = 'pending',
    inValid = 'inValid',
}

export interface __UserData {
    id: number;
    user_id: number;
    full_name?: string;
    username?: string;
    email?: string;
    last_message: Message;
    avatar?: string;
    first_name?: string;
    last_name?: string;
}

export interface __User {
    full_name: string;
    avatar: string;
    email: string;
    first_name: string;
    id: number;
    is_email_validated: boolean;
    is_lawyer: boolean;
    last_login: string;
    last_name: string;
    phone: string;
    username: string;
    user_id: number;
    city: string;
    province: string;
    gender: string;
}
export interface __Attachment {
    id: number;
    alt: string;
    image: string;
}
