import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './QA.style.scss';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Button, PaginationComponent } from 'vekalapp-react-utilities';
import { get, responseValidator } from '../../../scripts/api';
import { API, RoutePath } from '../../../data';
import { QuestionInterface } from './questionCard/questionCard.interface';
import QuestionCard from './questionCard/questionCard.index';
import ENV from 'env.json';
import emptyStateIMG from 'assets/images/277.svg';

const QuestionsAndAnswers: React.FC<ConnectedProps<typeof connector>> = function (
    props: ConnectedProps<typeof connector>,
) {
    const [questionList, setQuestionList] = useState<QuestionInterface[]>();
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const history = useHistory();
    useEffect(() => {
        getQuestions();
    }, [page]);

    function getQuestions() {
        const params = {
            page_size: 10,
            page: page,
            my_questions: true,
            order: 'newest',
        };
        setQuestionList(undefined);
        get<Pagination<QuestionInterface>>(API.QA.questionList, params).then((data) => {
            if (responseValidator(data.status) && data.data) {
                setQuestionList(data.data.data);
                setTotalPages(data.data.num_of_pages);
            }
        });
    }
    return (
        <div className="qa-container">
            <div className="qa-header-container">
                <div>
                    <h1 className="title">پرسش و پاسخ حقوقی</h1>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.QA.index} key="2">
                                پرسش و پاسخ حقوقی
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <a className="d-md-none" href={ENV.main + '/qa/'}>
                        <Button>
                            <i className="material-icons">add</i>
                        </Button>
                    </a>
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
                <a className="d-none d-md-flex" href={ENV.main + '/qa/'}>
                    <Button className="pl-4 pr-4">
                        <i className="material-icons ml-2">add</i>
                        <span>ساخت پرسش جدید</span>
                    </Button>
                </a>
            </div>
            {questionList && questionList.map((item, index) => <QuestionCard data={item} key={index} />)}
            {questionList && questionList.length === 0 ? (
                <div className="empty-state">
                    <img src={emptyStateIMG} alt="empty state" />
                    <p>شما تا کنون پرسشی ثبت نکرده اید</p>
                </div>
            ) : null}
            {!questionList ? <div className="vapp-loading-element" /> : null}
            <PaginationComponent currentPage={page} pageCount={totalPages} onChange={setPage} isShowArrow={false} />
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(QuestionsAndAnswers);
// TODO: Add Skeleton
