import React from 'react';
import { ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './contractsCard.style.scss';
import { ContractCardInterface } from './contractsCard.interface';
import { Button } from 'vekalapp-react-utilities';
import altImage from 'assets/images/default.jpg';
import downloadImage from 'assets/images/fileIcon.png';
import { Link } from 'react-router-dom';
import { RoutePath } from 'data';
import { detectOnlineStatus, getPastDiff } from 'scripts/date';
import { getContractTypeTittle } from '../contracts.script';

const ContractCard: React.FC<ConnectedProps<typeof connector> & ContractCardInterface> = function (
    props: ConnectedProps<typeof connector> & ContractCardInterface,
) {
    const image = props.data.lawyer?.profile_image.image ? props.data.lawyer?.profile_image.image : altImage;
    const name =
        props.data.lawyer?.user.first_name.length || props.data.lawyer?.user.last_name.length
            ? [props.data.lawyer.user.first_name.length, props.data.lawyer.user.last_name.length].join(' ')
            : 'بدون نام';
    const isPublic = !props.data.lawyer && props.data.parent;
    return (
        <div className="vekalapp-judicial-file-card">
            <div className="card-header">
                <div className="profile">
                    <img src={isPublic ? downloadImage : image} alt="profile" />
                    <div>
                        <h1>
                            <span>{isPublic ? props.data.title : name}</span>
                            {!isPublic ? <span>{props.data.lawyer?.rate}</span> : null}
                        </h1>
                        <p>
                            {isPublic
                                ? 'قرارداد آماده وکالپ'
                                : detectOnlineStatus(
                                      props.data.lawyer?.last_online ? props.data.lawyer.last_online : new Date(),
                                  )}
                        </p>
                    </div>
                </div>
                <Link className="d-none d-md-flex" to={RoutePath.judicialFile.detail(props.data.id)}>
                    <Button className="pr-4 pl-4" size="medium">
                        مشاهده بیشتر
                    </Button>
                </Link>
            </div>
            <div className="card-row">
                <p className="items">
                    <span className="q-title">نوع قرارداد: </span>
                    <span className="answer primary">{getContractTypeTittle(props.data.type)}</span>
                </p>
                <p className="items">
                    <span className="q-title">زمان درخواست: </span>
                    <a className="answer">{getPastDiff(props.data.created_at)}</a>
                </p>
            </div>
            <div className="card-row">
                <p className="items description">
                    <span className="q-title">{isPublic ? 'توضیحات: ' : 'شرح درخواست: '}</span>
                    <span className="answer">{props.data.description}</span>
                </p>
            </div>
            <Link className="d-flex d-md-none" to={RoutePath.judicialFile.detail(props.data.id)}>
                <Button className="pr-4 pl-4" size="medium">
                    مشاهده بیشتر
                </Button>
            </Link>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(ContractCard);
