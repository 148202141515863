import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import './qaDetail.style.scss';
import { get, responseValidator } from '../../../scripts/api';
import { API, RoutePath } from '../../../data';
import { QuestionDetailInterface } from './qaDetail.interface';
import { Breadcrumb, PaginationComponent } from 'vekalapp-react-utilities';
import AnswerCard from './answerCard/answerCard.index';
import { AnswerInterface } from './answerCard/answerCard.interface';
import ENV from 'env.json';

const QuestionsDetail: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const { id } = useParams<{ id: string }>();
    const [page, setPage] = useState<number>(1);
    const [answers, setAnswers] = useState<AnswerInterface[]>();
    const [question, setQuestion] = useState<QuestionDetailInterface>();
    const [answerCount, setAnswerCount] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(1);
    const history = useHistory();

    function getQuestionDetail() {
        get<QuestionDetailInterface>(API.QA.questionDetail(id), {}).then((data) => {
            if (responseValidator(data.status) && data.data) {
                setQuestion(data.data);
            }
        });
    }
    function getAnswersList() {
        const params = {
            page_size: 3,
            page: page,
        };
        get<Pagination<AnswerInterface>>(API.QA.questionAnswers(id), params).then((data) => {
            if (responseValidator(data.status) && data.data) {
                setAnswers(data.data.data);
                setTotalPages(data.data.num_of_pages);
                setAnswerCount(data.data.count);
            }
        });
    }
    useEffect(() => {
        getQuestionDetail();
    }, [id]);
    useEffect(() => {
        getAnswersList();
    }, [id, page]);
    return (
        <div className="question-detail-container">
            <div className="container-header">
                <h1 className="title">پرسش و پاسخ حقوقی</h1>
                <Breadcrumb
                    items={[
                        <Link to="/" key="1">
                            پنل کاربری
                        </Link>,
                        <Link to={RoutePath.QA.index} key="2">
                            پرسش و پاسخ حقوقی
                        </Link>,
                        <Link to={'#'} key="3">
                            جزئیات پرسش
                        </Link>,
                    ]}
                    className="d-none d-md-flex"
                />
                <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                    chevron_right
                </i>
            </div>
            {question && answers ? (
                <div className="question-detail">
                    <h3 className="question-header">{question?.title}</h3>
                    <p className="question-description">{question?.description}</p>
                    {question?.sim_questions && (
                        <div className="sim-questions">
                            <h4>سوالات حقوقی مرتبط: </h4>
                            {question?.sim_questions.map((simQuestion, index) => (
                                <a
                                    className="sim-question-linker"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${ENV.main}/qa/${simQuestion.id}`}
                                    key={index}
                                >
                                    {simQuestion.title}
                                </a>
                            ))}
                        </div>
                    )}
                    {/* <p className="qa-view-counter d-md-none">
                    <i className="cfi cfi-eye" />
                    بازدیدها:۵
                </p> */}
                </div>
            ) : null}
            {question && answers ? (
                <p className="qa-answer-divider d-md-none">
                    {answerCount
                        ? answerCount + ' پاسخ برای این سوال ثبت شده است'
                        : 'هنوز هیچ پاسخی برای این سوال ثبت نشده است'}
                </p>
            ) : null}
            {!question || !answers ? <div className="vapp-loading-element" /> : null}
            {question && answers && answers.map((item, index) => <AnswerCard data={item} key={index} />)}
            <PaginationComponent currentPage={page} pageCount={totalPages} onChange={setPage} isShowArrow={false} />
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(QuestionsDetail);
