import React, { useEffect, useState } from 'react';
import { Pagination, ReduxState, TicketDetailType, TicketListType, TicketMessages } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import './ticketDetail.style.scss';
import { Breadcrumb, Button, TextArea, TextInput } from 'vekalapp-react-utilities';
import { get, post, responseValidator } from '../../../scripts/api';
import { API, RoutePath } from '../../../data';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import emptyStateIMG from 'assets/images/277.svg';

const TicketDetail: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [description, setDescription] = useState<string>();
    const [data, setData] = useState<TicketDetailType>();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        get<TicketDetailType>(API.ticketing.ticketDetail(id)).then((res) => {
            if (responseValidator(res.status) && res.data) {
                setData(res.data);
            }
        });
    }, []);
    function submitHandler() {
        return new Promise((resolve) => {
            post(API.ticketing.ticketMessage, { ticket: id, text: description }).then((res) => {
                resolve(true);
                if (responseValidator(res.status)) {
                    toast.success('پیام شما با موفقیت ارسال شد.');
                    history.push(RoutePath.support.index);
                } else {
                    toast.error('خطایی رخ داده است لطفا دوباره تلاش کنید.');
                }
            });
        });
    }

    return (
        <div className="vekalapp-ticketing-detail">
            <div className="page-header">
                <div>
                    <p className="title">پشتیبانی</p>
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                پنل کاربری
                            </Link>,
                            <Link to={RoutePath.support.index} key="2">
                                پشتیبانی
                            </Link>,
                            <Link to={'#'} key="3">
                                جزئیات تیکت
                            </Link>,
                        ]}
                        className="d-none d-md-flex"
                    />
                    <i onClick={() => history.goBack()} className="material-icons back-button d-flex d-md-none">
                        chevron_right
                    </i>
                </div>
            </div>
            <div className="form">
                {data ? (
                    <>
                        <TextInput label="عنوان تیکت" disabled={true} value={data?.title} />
                        <TextArea disabled={true} value={data?.description} label="شرح تیکت" />
                    </>
                ) : null}
                {data &&
                    data.messages.length !== 0 &&
                    data.messages.map((item, index) => (
                        <TextArea
                            key={index}
                            value={item.text}
                            label={item.creator === props.userData?.id ? 'پاسخ شما' : 'پاسخ پشتیبانی وکالپ'}
                            disabled
                        />
                    ))}
                {data ? (
                    <>
                        <TextArea
                            onChange={setDescription}
                            value={description}
                            label={'متن پیام'}
                            placeholder="متن پیام خود را وارد نمایید"
                        />
                        <Button disabled={!description} type="filled" onClick={submitHandler}>
                            ثبت پیام
                        </Button>
                    </>
                ) : null}
                {data && data.messages.length === 0 && data.status === 1 ? (
                    <div className="empty-state">
                        <img src={emptyStateIMG} alt="empty state" />
                        <p>پشتیبانی در حال بررسی تیکت شماست</p>
                    </div>
                ) : null}
            </div>
            {!data ? <div className="vapp-loading-element" /> : null}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(TicketDetail);
