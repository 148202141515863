import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from 'redux/store';
import Vekalapp from './vekalapp/vekalapp.index';
import { BrowserRouter as Router } from 'react-router-dom';
import 'utilities/styles/index.scss';
import 'vekalapp-react-utilities/build/index.css';
function App() {
    return (
        <Provider store={store}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                style={{ zIndex: 1000000000000 }}
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router>
                <Vekalapp />
            </Router>
        </Provider>
    );
}

export default App;
